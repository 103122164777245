.success-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.success-content {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
}

.success-content h1 {
  color: #5436DA;
  margin-bottom: 1rem;
}

.success-content p {
  color: #536471;
  margin-bottom: 1rem;
}

.home-button {
  background-color: #5436DA;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.home-button:hover {
  background-color: #4425c9;
} 