.about-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
}

.about-main {
  padding-top: 67px;
}

.about-hero {
  min-height: 80vh;
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  color: white;
  position: relative;
  overflow: hidden;
}

.about-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 30% 30%, rgb(209 16 16) 0%, rgb(10 40 175) 70%);
  opacity: 0.1;
  animation: moveBackground 20s linear infinite;
}

@keyframes moveBackground {
  from { background-position: 0 0; }
  to { background-position: 100% 100%; }
}

.about-hero-content {
  max-width: 1200px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.hero-subtitle {
  font-size: 5rem;
  font-weight: 600;
  letter-spacing: 3px;
  color: #5436DA;
  margin-bottom: 1.5rem;
  display: block;
}

.about-hero-content h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.hero-description {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #a0a0a0;
  max-width: 700px;
  margin: 0 auto 3rem;
}

.hero-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #5436DA;
  margin-bottom: 0.5rem;
}

.stat-label {
  font-size: 0.9rem;
  color: #a0a0a0;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.stat-divider {
  width: 1px;
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
}

.about-vision {
  padding: 8rem 2rem;
  background: #ffffff;
}

.vision-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.section-subtitle {
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 2px;
  color: #5436DA;
  margin-bottom: 1rem;
  display: block;
}

.vision-text h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.vision-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 2rem;
}

.vision-features {
  list-style: none;
  padding: 0;
}

.vision-features li {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.feature-icon {
  font-size: 1.5rem;
  background: rgba(84, 54, 218, 0.1);
  padding: 0.5rem;
  border-radius: 12px;
}

.feature-text h4 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #1a1a1a;
}

.feature-text p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.vision-image img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.about-methodology {
  padding: 8rem 2rem;
  background: #f8f9fa;
}

.methodology-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.methodology-content h2 {
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #1a1a1a;
}

.methodology-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.methodology-card {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.methodology-card:hover {
  transform: translateY(-5px);
}

.methodology-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  display: block;
}

.methodology-card h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.methodology-card p {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.6;
}

.about-cta {
  padding: 8rem 2rem;
  background: linear-gradient(135deg, #5436DA 0%, #4425c9 100%);
  color: white;
  text-align: center;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-content h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

.cta-content p {
  font-size: 1.2rem;
  margin-bottom: 3rem;
  opacity: 0.9;
}

.cta-buttons {
  display: flex;
  justify-content: center;
}

.cta-button {
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button.primary {
  background: white;
  color: #5436DA;
  border: none;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .about-hero-content h1 {
    font-size: 2.5rem;
  }
  
  .vision-content {
    grid-template-columns: 1fr;
  }
  
  .hero-stats {
    flex-direction: column;
    gap: 2rem;
  }
  
  .stat-divider {
    display: none;
  }
  
  .cta-buttons {
    flex-direction: column;
  }
}

.about-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 12px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.pill-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-bottom: 2rem;
  filter: drop-shadow(0 4px 20px rgba(84, 54, 218, 0.2));
}
