.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.popup-content {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #536471;
}

.trial-details {
  text-align: center;
  margin: 2rem 0;
}

.trial-highlight {
  font-size: 1.5rem;
  color: #5436DA;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.then-text {
  color: #536471;
  margin-bottom: 1rem;
}

.price-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
}

.original-price {
  text-decoration: line-through;
  color: #536471;
}

.discount-price {
  font-size: 1.5rem;
  font-weight: 600;
  color: #5436DA;
}

.savings-tag {
  background: #5436DA;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

.feature-list li {
  margin: 0.5rem 0;
  color: #536471;
}

.start-trial-button {
  width: 100%;
  padding: 1rem;
  background-color: #5436DA;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-trial-button:hover {
  background-color: #4425c9;
}

.terms {
  text-align: center;
  color: #536471;
  font-size: 0.9rem;
  margin-top: 1rem;
}