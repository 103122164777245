/* HomePage.css */
.homepage-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: #FFFFFF;
}
  
.homepage-main-content {
    padding-top: 67px; /* To account for fixed navbar */
}
  
.homepage-hero-section {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 65vh;
    overflow: hidden;
}
  
.banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; /* Keep this z-index for the video */
}
  
/* Optional: Add an overlay to improve text readability */
.homepage-hero-section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
    z-index: 1; /* Keep this z-index for the overlay */
}
  
.homepage-hero-content {
    position: relative;
    z-index: 2; /* Keep this z-index for the content */
    color: white;
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
}
  
/* Update text colors for better contrast against video */
.homepage-hero-content h1 {
    color: #ffffff;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1.5rem;
}
  
.homepage-hero-content p {
    color: #f0f0f0;
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}
  
.homepage-cta-button {
    background-color: #5436DA;
    color: white;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.homepage-cta-button:hover {
    background-color: #4425c9;
}
  
.homepage-features-section {
    padding: 5rem 2rem;
}
  
.homepage-features-section h2 {
    text-align: center;
    color: #0E1117;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 3rem;
}
  
.homepage-features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}
  
.homepage-feature-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}
  
.homepage-feature-card:hover {
    transform: translateY(-5px);
}
  
.feature-icon {
    font-size: 3.5rem;
    margin-bottom: 1rem;
}
  
.homepage-feature-card h3 {
    color: #0E1117;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
  
.homepage-feature-card p {
    color: #536471;
    font-size: 1rem;
    line-height: 1.5;
}

.homepage-benefits-section {
    padding: 6rem 2rem;
    background-color: #F8F9FA;
}

.homepage-benefits-content {
    max-width: 1200px;
    margin: 0 auto;
}
.homepage-benefits-content h2{
    font-size: 3rem;
}

.homepage-benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 3rem;
    margin-top: 4rem;
}

.homepage-benefit-item {
    position: relative;
    padding: 2rem;
}
.homepage-benefit-item h3{
    font-size: 2rem;
}

.homepage-benefit-number {
    font-size: 4rem;
    font-weight: 700;
    color: rgba(84, 54, 218, 0.1);
    position: absolute;
    top: 20px;
    left: 0;
}

.homepage-testimonials-section {
    padding: 6rem 2rem;
    background-color: #FFFFFF;
}

.homepage-testimonials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 3rem auto 0;
}

.homepage-testimonial-card {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.homepage-testimonial-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.homepage-testimonial-author {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding-top: 1.5rem;
}

.homepage-testimonial-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
}

.homepage-testimonial-content p {
    color: #2d3436;
    font-size: 1.1rem;
    line-height: 1.6;
    font-style: italic;
    margin-bottom: 1.5rem;
}

.homepage-testimonial-author h4 {
    color: #0E1117;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
}

.homepage-testimonial-author p {
    color: #536471;
    font-size: 0.9rem;
    margin: 0;
}

.homepage-testimonial-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.homepage-testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.homepage-stats-section {
    padding: 5rem 2rem;
    background: linear-gradient(135deg, #5436DA 0%, #4425c9 100%);
    color: white;
}

.homepage-stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 3rem;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

.homepage-stat-card h3 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.homepage-cta-section {
    padding: 6rem 2rem;
    background-color: #F8F9FA;
    text-align: center;
}

.homepage-cta-content {
    max-width: 800px;
    margin: 0 auto;
}

.homepage-cta-button-secondary {
    background-color: transparent;
    border: 2px solid #5436DA;
    color: #5436DA;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 2rem;
}

.homepage-cta-button-secondary:hover {
    background-color: #5436DA;
    color: white;
}

/* Add responsive design */
@media (max-width: 768px) {
    .homepage-hero-content h1 {
        font-size: 2.5rem;
    }
    
    .homepage-benefits-grid,
    .homepage-testimonials-grid,
    .homepage-stats-grid {
        grid-template-columns: 1fr;
    }
}

.homepage-promotion-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f8f9ff 0%, #ffffff 100%);
}

.promotion-container {
  max-width: 1200px;
  margin: 0 auto;
}

.promotion-content {
  position: relative;
  background: white;
  border-radius: 20px;
  padding: 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.promotion-text {
  max-width: 600px;
  z-index: 1;
}

.limited-offer {
  background: #ff6b6b;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 1rem;
}

.promotion-text h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #2d3436;
}

.pricing {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.original-price {
  font-size: 1.5rem;
  text-decoration: line-through;
  color: #a0a0a0;
}

.discount-price {
  font-size: 3rem;
  font-weight: 700;
  color: #2d3436;
}

.savings {
  background: #4CAF50;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
}

.promotion-features {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

.promotion-features li {
  margin: 1rem 0;
  color: #2d3436;
  font-size: 1.1rem;
}

.promotion-cta-button {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 1rem;
  width: fit-content;
}

.promotion-cta-button:hover {
  transform: translateY(-2px);
}

.timer {
  font-size: 0.9rem;
  opacity: 0.9;
}

.promotion-badge {
  position: absolute;
  top: -20px;
  right: -20px;
  background: #ffd700;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(15deg);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.badge-content {
  text-align: center;
}

.badge-text {
  display: block;
  color: #2d3436;
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .promotion-content {
    padding: 2rem;
  }
  
  .promotion-text h2 {
    font-size: 2rem;
  }
  
  .promotion-badge {
    width: 90px;
    height: 90px;
    top: -10px;
    right: -10px;
  }
  
  .badge-text {
    font-size: 1rem;
  }
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tax-note {
  font-size: 0.8rem;
  color: #666;
  margin-top: -5px;
}

.homepage-testimonials-section h2 {
  text-align: center;
  color: #0E1117;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
}