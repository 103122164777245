/* Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .navbar-brand h1 {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.8rem;
  }
  
  .logo-icon {
    font-size: 1.5em;
    animation: pulse 2s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  .nav-links {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  
  .nav-link {
    color: #536471;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: #0E1117;
  }
  
  .nav-link.free-trial-btn {
    background-color: #5436DA;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 500;
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .nav-link.free-trial-btn:hover {
    background-color: #4425c9;
    color: white;
  }